// Mixin - Pseudo element
@mixin pseudo-element {
  content: '';
  position: absolute;
  display: block;
}

// Logo



// Carousel - Layout - allow the slide detail to overlap
.carousel { z-index: 1; }
.carouselSlide { overflow: visible; }

// Home Intro 
.homeIntro {
  z-index: 1;
  padding: #{$card-gap-width / 2};
  @media (max-width: map-get($breakpoints, lg)) {
    max-width: none;
    margin-bottom: $spacer * 2;
  }
  > * { color: text-contrast($home-features-background-colour); }
  h2{
    @include heading-underline();
  }
}

// Home Features - Layout
.homeFeatures{
  padding-left: 0;
  padding-right: 0;
}
.homeFeaturesInner {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  max-width: calc(100% - 350px);
  @media (max-width: map-get($breakpoints, lg)) {
    max-width: 100%;
  }
}

// Impact section - Layout
.homefeaturecategory-homeboximpactstats .homeImpactWrapper {
  padding:  0;
  flex-direction: unset;
  flex-wrap: wrap;
  
  // Impact table - Layout
  table {
    margin-right: 0;
    margin-top: 0;
    max-width: calc(100% - 350px);
    @media (max-width: map-get($breakpoints, lg)) {
      max-width: 100%;
      margin: 15px;
    }
  }

  // Impact title - Layout
  > h2 {
    padding: #{$card-gap-width / 2};
    @include heading-underline(
      $colour: $brand-secondary
    );

  }
}

// Impact section - Background
.homefeaturecategory-homeboximpactstats:before {
  @include pseudo-element;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background-color: $impact-stats-background-colour;
}

// Home Feeds - Layout
.homeFeed {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  //Feed - Background
  &:before {
    @include pseudo-element;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
  }
}

// Feed specific
ul.feedList {
  width: 100%;
  max-width: calc(100% - 350px);
  margin: 0 0 0 auto;
  @media (max-width: map-get($breakpoints, lg)) {
    max-width: 100%;
  }
}

// Feeds Title - Layout
.feedsTitle {
  width: auto;
  max-width: 350px;
  position: relative;
  margin: 0;  
}

// Feed Specific - Odd
.homeFeed:nth-of-type(odd) {
  background-color: $brand-secondary;
  &:before { background-color: $brand-secondary; }
  .feedsTitle { 
    color: text-contrast($brand-secondary); 
    @include heading-underline(
      $colour: $brand-primary
    );
  }
}

// Feed Specific - Even
.homeFeed:nth-of-type(even) {
  background-color: $brand-primary;
  &:before { background-color: $brand-primary; }
  .feedsTitle { 
    color: text-contrast($brand-primary); 
    @include heading-underline(
      $colour: $brand-secondary
    );
  }
}

.footerBox {
  ul li {
    display: inline-flex;
    margin: 0 20px 10px 0;
  }
}

/* custom form */

body.forms-signup {
  background-image: url($assets-path + 'BU-bg6.jpg');
  background-size: 100%;
  background-attachment: fixed;
  background-position: 0 -30px;
  .mainLogo{
    top:20px;
  }
  div#customFormContainer {
    max-width: $container-max-width;
    padding: 0 20px;
    margin: 20px auto;
  }
  @media (min-width: map-get($breakpoints, lg) + 1px) {
    .pageHeader {
      position: fixed;
      top: 0;
    }
    .mainLogo{
      top:40px;
      right: 0;
      left: auto;
    }
    div#customFormContainer {
      margin: 40px auto;
    }
  }
  .customFormInner { /* New div injected via jquery */
    background: white;
    padding: 50px;
    max-width: 600px;
  }
  .buttonContainer {
    background: white;
    padding: 0 50px 50px;
    max-width: 600px;
    margin: 0 0 40px;
  }
  .customForm {
    padding-top: 0;
  }
  .customForm .checkboxRadioGrid .answerOptions {
    display: flex;
  }
}

.footerBox:not([class*="ewsletter"]) {
  margin-top: 0;
}